<template>
  <div :class="{container: activeIndex === 0}">
    <div class="header">
      <img alt="" class="header-logo" src="@/assets/logo.png" />
      <!-- <div style="display: flex; margin-right: 300px;">
        <template
          class="header-routers"
          v-for="(item, index) in routers"
          :key="index"
        >
          <div
            v-if="index <= 2"
            :class="{ active: index === activeIndex }"
            class="header-routers-item"
            @click="changeRouter(index)"
          >
            <div>{{ item.name }}</div>
          </div>
        </template>
      </div>
      <div v-if="activeIndex == 4" class="back-btn" @click="changeRouter(0)">
        <img src="@/assets/go-back.png" alt="">
        <p>返回官网</p>
      </div>
      <div v-else class="btns" @click="changeRouter(4)">登录</div> -->
    </div>
    <div style="margin-top:100px;">
      <component :is="component" class="components" @changeNav="changeRouter" :activeIndex="activeIndex" @close="$emit('close')" @contactUs="wantTry"></component>
    </div>  
    <!-- Copyright -->
    <div class="footer-box" style="scroll-snap-align: center;">
      <div class="innner-box">
        <div class="footer-box-top">
          <div class="lx-1">
            <img class="logo" src="@/assets/logo.png" alt="">
          </div>
          <div class="lx-2">
            <p>联系我们</p>
            <p>联系电话： 010-64933553</p>
            <p>公司地址： 北京市朝阳区北辰东路8号院1号楼（北辰时代大厦）20层2008号</p>
            <p style="margin-left: 85px">重庆市江北区庆云路16号国金中心T2塔楼32层</p>
          </div>
          <div class="lx-3">
            <div class="qrcode">
              <img src="@/assets/1v1.png" alt="">
              <p>扫码1v1咨询</p>
            </div>
            <div class="qrcode">
              <img src="@/assets/wechat.png" alt="">
              <p>微信公众号</p>
            </div>
          </div>
        </div>
        <div class="footer-box-bottom">
          <a class="footer-box-bottom-txt" href="https://beian.miit.gov.cn/" target="_blank">Copyright©北京弘运保科技有限公司 京ICP备2020048651号-4</a>
        </div>
      </div>
    </div>

    <!-- contact-us -->
    <div class="contact-us">
      <!-- <div class="contact-us-box" style="margin: 0 0 30px 0" @click="wantTry">
        <img src="@/assets/p3i5.png" alt="">
        <p>我要融资</p>
      </div> -->
      <el-popover placement="left" :width="180"  trigger="hover" offset="30" :teleported="false">
        <template #reference>
          <div class="contact-us-box bg_fff">
            <img src="@/assets/call2.png" alt="">
            <p>联系我们</p>
          </div>
        </template>
        <div>
          <img style="width: 160px" src="@/assets/1v1.png" alt="">
          <p style="text-align:center">扫码1v1咨询</p>
          <img style="width: 160px;margin-top: 10px" src="@/assets/wechat.png" alt="">
          <p style="text-align:center">微信公众号</p>
        </div>
      </el-popover>
    </div>
  </div>

  <!-- 验证码 -->
  <el-dialog v-model="codeNumVis" title="请输入短信验证码" width="450px" @close="closeCodeNum">
    <div class="validate-code-content">
      <div class="item">
        <span class="label">手机号</span>
        <el-input size="mini" v-model="formVal.phone" disabled />
      </div>
      <div class="item codeNum">
        <span class="label">短信验证码</span>
        <el-input size="mini" v-model="formVal.codeNum" maxlength="4">
          <template #suffix>
            <span style="cursor: pointer; color: #606266" @click="sendCode">{{ codeData }}</span>
          </template>
        </el-input>
      </div>
    </div>
    <template #footer>
      <el-button @click="codeNumVis = false">我再想想</el-button>
      <el-button type="primary" @click="codeNumConfirm">确认</el-button>
    </template>
  </el-dialog>

  <!-- 我要融资 -->
  <el-dialog v-model="conVis" title="注册物流宝账号，提交融资申请" width="80%" @close="closeCon">
    <div class="screen-seven-support" style="margin-top: 0;">
      <p class="title">物流宝，专注物流金融，助力物流企业快速获得资金支持</p>
      <div class="input-box">
        <el-input
          placeholder="您的姓名"
          v-model="formVal.name"
          style="width: 136px"
          >
          <template #prefix><i style="color: red">*</i></template>
        </el-input>
        <el-input
          placeholder="您的手机号"
          v-model="formVal.phone"
          style="width: 152px"
          >
          <template #prefix><i style="color: red">*</i></template>
        </el-input>
        <el-input
          placeholder="您的公司名称"
          v-model="formVal.company"
          style="width: 152px"
          >
          <template #prefix><i style="color: red">*</i></template>
        </el-input>
        <el-input
          placeholder="请描述您的需求，我们将尽快与您联系"
          v-model="formVal.remarks"
          style="width: 350px"
          >
          <template #prefix><i style="color: red">*</i></template>
        </el-input>
        <div class="btn btn-confirm" @click="confirmClue">立即获得</div>
      </div>
      <img src="@/assets/car.png" alt="">
    </div>
  </el-dialog>
</template>

<script>
  import { defineComponent, reactive, ref, unref, toRaw } from 'vue';
  import home from './temporary/home.vue';
  import service from './temporary/service.vue';
  import about from './temporary/about.vue';
  import loginPane from './temporary/loginPane.vue';
  import { ElPopover, ElMessage } from 'element-plus'
  import _service from '@/api';

  export default defineComponent({
    name: 'Temporary',
    components: { home, ElPopover },
    setup(props, { emit }) {
      function showLogin() {
        let locationUrl = window.location.href;
        console.log(locationUrl);
        if (locationUrl.includes('http://56bank.cn/login')) {
          locationUrl = 'http://admin.56bank.cn/login';
        } else {
          emit('close');
        }
      }

      const component = ref(loginPane);
      const activeIndex = ref(4);
      const routers = ref([
        { name: '首页', components: home },
        { name: '系统服务', components: service },
        { name: '关于我们', components: about },
        { name: '', components: home },
        { name: '', components: loginPane },
      ]);
      const changeRouter = (index) => {
        activeIndex.value = index;
        component.value = routers.value[activeIndex.value].components;
      };
      const wantTry = () => {
        conVis.value = true;
      }
      const conVis = ref(false);
      const codeNumVis = ref(false);
      const qrcodeVis = ref(false);
      const formVal = ref({
        name: '',
        phone: '',
        company: '',
        remarks: '',
        codeNum: ''
      })

      const codeData = ref('立即发送');
      const codeToken = ref('');
      const timer = ref()

      const getCodeFun = async () => {
        const value = await _service.getCode({telephone: formVal.value.phone});
        codeToken.value = value;
        if (value.errno == 0 || value) {
          ElMessage.success('已成功发送验证码，请注意查收');
        }
      };
      // 发送验证码
      const sendCode = ()=> {
        if (codeData.value !== '立即发送') {
          return;
        }
        getCodeFun();
        let time = 179;
        codeData.value = time + 's';
        timer.value = setInterval(function () {
          if (time > 1) {
            time--;
            codeData.value = time + 's';
          } else {
            codeData.value = '立即发送';
            clearInterval(timer.value);
          }
        }, 1000);
      }

      const codeNumConfirm = async() => {
        if (!formVal.value.codeNum) {
          ElMessage.error('请输入验证码！');
          return;
        }
        const res = await _service.validateSmsCode({smsCode: formVal.value.codeNum, smsToken: codeToken.value});
        if(res){
          codeNumVis.value = false;
          _service.addCrmTmsClue({
            customerName: formVal.value.company,
            customerContactName: formVal.value.name,
            customerContactMobile: formVal.value.phone,
            source: 'website',
            remark: formVal.value.remarks
          }).then(res => {
            ElMessage.success('提交成功！');
            conVis.value = false
          })
        }
      }

      const confirmClue = () => {
        if(!formVal.value.name) {
          ElMessage.error('请填写您的姓名');
          return;
        }
        if(!formVal.value.phone) {
          ElMessage.error('请填写您的手机号');
          return;
        }
        if(!/^1[3-9]\d{9}$/.test(formVal.value.phone)) {
          ElMessage.error('请填写正确的手机号');
          return;
        }
        if(!formVal.value.company) {
          ElMessage.error('请填写您的公司名称');
          return;
        }
        if(!formVal.value.remarks) {
          ElMessage.error('请填写您的备注');
          return;
        }
        codeNumVis.value = true;
      }

      const showPopUp = () => {
        qrcodeVis.value = true;
      }

      const closeCodeNum = () => {
        clearInterval(timer.value);
        codeData.value = '立即发送';
        formVal.value.codeNum = ''
      }

      const closeCon = () => {
        formVal.value = {
          name: '',
          phone: '',
          company: '',
          remarks: '',
          codeNum: ''
        }
      }

      return {
        showLogin,
        activeIndex,
        routers,
        changeRouter,
        component,
        wantTry,
        conVis,
        codeNumVis,
        qrcodeVis,
        formVal,
        showPopUp,
        sendCode,
        codeData,
        codeNumConfirm,
        confirmClue,
        closeCodeNum,
        closeCon
      };
    },
  });
</script>

<style lang="less" scoped>
  .container {
    height: 100%;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }

  .header {
    width: 100%;
    display: flex;
    height: 100px;
    align-items: center;
    background: #ffffff;
    position: fixed;
    top: 0;
    z-index: 2;
    &-logo {
      width: 349px;
      margin-left: 15%;
    }

    &-routers {
      &-item {
        margin: 11px 0 0 82px;
        display: inline-block;
        width: 80px;
        padding-bottom: 11px;
        font-size: 18px;
        font-weight: 400;
        color: #898989;
        cursor: pointer;
        text-align: center;
        border-bottom: 3px solid transparent;
        box-sizing: border-box;
        transition: all 0.15s linear;

        &:not(:first-child) {
          margin-left: 82px;
        }

        div {
          transition: all 0.2s linear;
        }

        &.active,
        &:hover {
          div {
            font-weight: 500;
            color: #2D68FF;
          }

          border-bottom-color: #2D68FF;
        }

        // &:hover {
        //   div:first-child {
        //     transform: translateY(110%);
        //   }

        //   div:last-child {
        //     transform: translateY(-110%);
        //   }
        // }
      }
    }

    .btns {
      height: 44px;
      padding: 0 40px;
      // margin-left: 156px;
      font-size: 18px;
      font-weight: 500;
      line-height: 44px;
      color: #2D68FF;
      text-align: center;
      cursor: pointer;
      border: 1px solid #2D68FF;
      border-radius: 22px;
      user-select: none;
      position: absolute;
      right: 18.2%;
    }
    .back-btn {
      text-align: center;
      cursor: pointer;
      font-size: 18px;
      font-weight: 500;
      color: #2D68FF;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 18.2%;
      img {
        width: 31px;
        height: 31px;
        margin-right: 10px;
      }
    }
  }

  .components {
    padding-bottom: 50px;
  }

  .footer-box {
    width: 100%;
    height: 364px;
    background-color: #DCDCDC;
    color: #3E3A39;
    margin-top: 150px;
    .innner-box {
      width: 1349px;
      height: 100%;
      margin: 0 auto;
      padding: 27px 80px;
      position: relative;
    }
    &-top {
      position: relative;
      .lx-1 {
        border-bottom: 1px solid #B5B5B5;
        padding-bottom: 22px;
        .logo {
          width: 349px;
        }
      }
      .lx-2 {
        width: 600px;
        height: 80px;
        margin-top: 22px;
        line-height: 30px;
        font-size: 16px;
        p:nth-of-type(1) {
          font-weight: 600;
        }
      }
      .lx-3 {
        position: absolute;
        right: 0;
        top: 160px;
        display: flex;
        .qrcode {
          display: flex;
          margin-right: 20px;
          flex-flow: column;
        }
        img {
          width: 100px;
          height: 100px;
        }
        p {
          text-align: center;
          font-size: 13px;
          margin-top: 5px;
        }
      }
    }
    &-bottom {
      width: 100%;
      text-align: center;
      position: absolute;
      right: 0;
      bottom: 20px;
      &-txt {
        color: #3E3A39;
        text-decoration: none;
        font-size: 13px;
        display: block;
        margin-top: 30px;
      }
    }
  }

  .contact-us {
    position: fixed;
    right: 20px;
    bottom: 50px;
    &-box {
      width: 52px;
      height: 129px;
      background: #1657CE;
      box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.19);
      border-radius: 10px;
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 15px;
      user-select:none;
      cursor: pointer;
      opacity: 0.7;
      p {
        writing-mode:vertical-lr;
        margin-top: 10px;
        letter-spacing: 3px;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
    .bg_fff {
      background-color: #fff;
      color: #494949;
      margin-top: 30px;
    }
    &-box:hover{
      opacity: 0.9;
      box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.3);
    }
  }
  .screen-seven {
    width: 1349px;
    margin: 115px auto;
    &-support {
    width: 100%;
    height: 351px;
    background: url('@/assets/p5bg.png') no-repeat center;
    background-size: 100% 100%;
    margin-top: 33px;
    position: relative;
    padding: 81px 90px 107px 90px;
    .title {
      font-size: 30px;
      color: #fff;
      font-weight: 500;
      text-align: center;
    }
    img {
      width: 187px;
      height: 90px;
      position: absolute;
      right: 82px;
      bottom: 10px;
    }
    .input-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 60px;
      .btn-confirm {
        width: 121px;
        height: 42px;
        background: #F19149;
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        cursor: pointer;
        user-select: none;
        color: #fff;
        text-align: center;
        line-height: 42px;
        font-size: 20px;
      }
    }
    }
  }
  // codeNum
  .validate-code-content {
    .item {
      display: flex;
      width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;

      .label {
        display: inline-flex;
        width: 100px;
        align-items: center;
      }

      &.codeNum {
        position: relative;

        .unit {
          position: absolute;
          top: 6px;
          right: 10px;
          cursor: pointer;
        }
      }
    }
  }
</style>

<style lang="less">
  .contact-us {
    .second-popover {
      top: 90px!important;
      .el-popper__arrow {
        top: 100px!important;
      }
    }
  }
</style>
